@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  .text-sn-h1-lg {
    font-family: "microgramma-extended";
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 108px */
  }

  .text-sn-h1-sm {
    font-family: "microgramma-extended";
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 54px */
  }

  .text-sn-h2-lg {
    font-family: "microgramma-extended";
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 64.8px */
  }

  .text-sn-h2-sm {
    font-family: "microgramma-extended";
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 48.6px */
  }

  .text-sn-h3-lg {
    font-family: "microgramma-extended";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 50.4px */
  }

  .text-sn-h3-sm {
    font-family: "microgramma-extended";
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 39.6px */
  }

  .text-sn-h4-lg {
    font-family: "microgramma-extended";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 43.2px */
  }

  .text-sn-h4-sm {
    font-family: "microgramma-extended";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 32.4px */
  }

  .text-sn-h5-lg {
    font-family: "microgramma-extended";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 28.8px */
  }

  .text-sn-h5-sm {
    font-family: "microgramma-extended";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 25.2px */
  }

  .text-sn-h6-lg {
    font-family: "microgramma-extended";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 21.6px */
  }

  .text-sn-h6-sm {
    font-family: "microgramma-extended";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 19.8px */
  }

  .text-sn-lg {
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 28px */
    letter-spacing: 0.2px;
  }

  .text-sn-lg-1 {
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    letter-spacing: 0.2px;
  }

  .text-sn-md {
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.18px;
  }

  .text-sn-md-1 {
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.18px;
  }

  .text-sn-base {
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }

  .text-sn-base-1 {
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }

  .text-sn-sm {
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.14px;
  }

  .text-sn-sm-1 {
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }

  .text-sn-xs {
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.12px;
  }

  .text-sn-xs-1 {
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.12px;
  }

  .text-sn-xss {
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 14px */
    letter-spacing: 0.1px;  
  }
}

body {
  background-color: #17151E;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
